import React from 'react'

const SectionOneEng = () => {
    return (
        <>
            <section className='w-full h-full bg-[#f8fcff] font-dosis font-semibold mt-[-100px] md:mt-[-50px]'>
                <div>
                    <div>
                        <div className='flex justify-center'>
                            <div data-aos="fade-up"
                                data-aos-duration="1000" className='w-[200px] h-full'>
                                <h1 className="wavy text-xl flex justify-center text-center" href="#">Advantages of drinking more water</h1>
                            </div>
                        </div>
                        <br />
                        <div className='flex justify-evenly md:text-[20px] sm:text-[15px] text-[12px] mx-4 text-center'>

                            {/* div 1 */}
                            <div className='flex justify-center items-center'>
                                <div className=''>
                                    {/* img-1 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 ml-[80px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675412384/strong-removebg-preview_dmieuv.png" alt="strong" />
                                        </div>
                                        <div>
                                            <p>
                                                It is necessary for muscle growth
                                            </p>
                                        </div>
                                    </div>
                                    {/* img-2 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 mr-[20px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675500119/weight1-removebg-preview_ihfxt9.png" alt="weight" />
                                        </div>
                                        <div>
                                            <p>
                                                Promotes weight loss
                                            </p>
                                        </div>
                                    </div>
                                    {/* img-3 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 ml-[80px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675501646/liver1-removebg-preview_xqlfbw.png" alt="liver" />
                                        </div>
                                        <div>
                                            <p>
                                                It helps the liver
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* div 2 */}
                            <div data-aos="fade-up"
                                data-aos-duration="1000" className='md:w-[30%] md:h-[30%] h-[50%] w-[50%] md:mt-14 mt-[100px]'>
                                <img src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675411646/9d247122-606e-4b19-98cc-328bcd9d7107_v3ll3t.png" alt="splash" />
                            </div>

                            {/* div 3 */}
                            <div className='flex justify-center items-center'>
                                <div className=''>
                                    {/* img-1 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 mr-[80px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675501852/heart1-removebg-preview_pvtpju.png" alt="strong" />
                                        </div>
                                        <div>
                                            <p>
                                                Reduces the risk of heart attack
                                            </p>
                                        </div>
                                    </div>
                                    {/* img-2 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 ml-[20px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[80px] w-[30px] h-[40px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675502105/protect-removebg-preview_fgpod1.png" alt="weight" />
                                        </div>
                                        <div>
                                            <p>
                                                Strengthens the immune system
                                            </p>
                                        </div>
                                    </div>
                                    {/* img-3 */}
                                    <div data-aos="fade-up"
                                        data-aos-duration="1000" className='mt-4 mr-[80px]'>
                                        <div className='flex justify-center'>
                                            <img className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' src="https://res.cloudinary.com/dcrolfqsj/image/upload/v1675502267/p1-removebg-preview_1_k7oqv6.png" alt="liver" />
                                        </div>
                                        <div>
                                            <p>
                                                Improves the appearance of the skin
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionOneEng
